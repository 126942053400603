import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Form, Formik } from 'formik';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import Input from 'components/FormInput/FormInput';
import Select from 'components/Select';
import Button from 'components/Button/Button';
import Close from 'assets/icons/close.svg';

import styles from './centerForm.module.scss';
import { assignAdminAction, fetchCentreRolesAction } from 're-ducks/centers';
import { removeSpaceRegExp } from 'utils/validation';

const AssignAdminForm = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();

  const [setRole, setSelectSetRole] = useState('');
  const [selectuserRole, setSelectuserRole] = useState();
  const [loading, setLoading] = useState(false);
  const [allRoles, setAllRoles] = useState();

  const [allUsers, setAllusers] = useState([
    {
      email: '',
      role_id: ''
    }
  ]);

  useEffect(() => {
    dispatch(
      fetchCentreRolesAction(
        { type: 'Center' },
        (res) => {
          setLoading(false);
          setAllRoles(res);
        },
        (error) => {
          setLoading(false);
          toast.error(error);
        }
      )
    );
  }, []);

  useEffect(() => {
    const { id } = { ...setRole };
    setSelectuserRole({ name: id ?? '' });
    setSelectSetRole();
  }, [setRole]);

  const handleAdUsers = () => {
    const values = [...allUsers];
    values.push({
      email: '',
      role_id: ''
    });
    setAllusers(values);
  };

  const handleRemoveFields = (index) => {
    const values = [...allUsers];
    values.splice(index, 1);
    setAllusers(values);
  };
  const getAllUsers = [...allUsers];

  // values.email.replace(removeSpaceRegExp, '').trim()

  const handleAssignAdmin = () => {
    const payload = {
      admins: allUsers?.map((i) => ({
        email: i?.email?.replace(removeSpaceRegExp, '')?.trim(),
        role_id: i?.role_id?.id
      }))
    };
    setLoading(true);

    dispatch(
      assignAdminAction(
        id,
        payload,
        () => {
          navigate(`/assign-procedure/${id}`);
          setLoading(false);
          toast.success('Admin assigned to centre');
        },
        (err) => {
          setLoading(false);
          toast.error(err);
        }
      )
    );
  };

  const handleChange = (index, e) => {
    const values = [...allUsers];
    values[index].email = e.target.value;
    setAllusers(values);
  };

  const handleRoleChange = (index, e) => {
    const values = [...allUsers];
    values[index].role_id = e;
    setAllusers(values);
  };

  return (
    <div className={styles.mainform}>
      <div>
        {allUsers?.map((field, index) => (
          <div key={index}>
            {getAllUsers.length > 1 && (
              <div className={styles.mainform__cancel} onClick={() => handleRemoveFields(index)}>
                <img src={Close} />
              </div>
            )}

            <div className={styles.mainform__input}>
              <Input
                label="Email Address"
                type="text"
                placeholder="Email Address"
                value={allUsers[index].email}
                onChange={(e) => handleChange(index, e)}
                name="email"
              />
            </div>
            <Select
              label="Role Type"
              type="text"
              placeholder="select"
              name="role_id"
              options={allRoles?.map((item) => ({
                name: item.name,
                id: item.id
              }))}
              selected={allUsers[index].role_id}
              handleChange={(e) => handleRoleChange(index, e)}
            />
            <p className={styles.mainform__links}>
              <div className={styles.mainform__links} onClick={() => handleAdUsers()} aria-hidden>
                + Add other users
              </div>
            </p>
          </div>
        ))}
        <div>
          <Button
            theme="primary"
            size="md"
            type="submit"
            disabled={loading}
            loading={loading}
            onClick={() => handleAssignAdmin()}
          >
            Continue
          </Button>
          <div className={styles.mainform__skip}>
            <p onClick={() => navigate(`/assign-procedure/${id}`)}>Skip</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AssignAdminForm;
