import React from 'react';
import styles from '../../centerFormLayout/centerForm.module.scss';
import TimePicker from 'components/TimePicker/TimePicker';
import Button from 'components/Button/Button';

const WorkingHoursModal = ({ formik, setShowHours, edit }) => {
  return (
    <div className={edit ? styles.hourModal : styles.hoursModal}>
      <p>Weekdays</p>
      <div className={styles.hoursModal__week}>
        <TimePicker
          label="Opening time"
          value={formik.values.time_open_weekday}
          onChange={(e) => formik?.setFieldValue('time_open_weekday', e)}
        />{' '}
        <TimePicker
          label="Closing time"
          value={formik.values.time_closed_weekday}
          onChange={(e) => formik?.setFieldValue('time_closed_weekday', e)}
        />
      </div>
      <p>Saturdays</p>
      <div className={styles.hoursModal__week}>
        <TimePicker
          label="Opening time"
          value={formik.values.time_open_weekend}
          onChange={(e) => formik?.setFieldValue('time_open_weekend', e)}
        />{' '}
        <TimePicker
          label="Closing time"
          value={formik.values.time_closed_weekend}
          onChange={(e) => formik?.setFieldValue('time_closed_weekend', e)}
        />
      </div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button theme="primary" onClick={() => setShowHours(false)}>
          Save
        </Button>
      </div>
    </div>
  );
};

export default WorkingHoursModal;
