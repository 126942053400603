import DashboardLayout from 'container/layouts/dashboard';
import Arrow from 'assets/icons/arrow-image.svg';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import styles from '../websiteManagement.module.scss';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import placeholder from 'assets/icons/table-placeholder.svg';
import {
  curatePackagesAction,
  fetchWebsiteCategoryDetailsAction,
  fetchWebsiteSubCategoryDetailsAction
} from 're-ducks/websiteManagement';
import CardList from 'components/CardList/CardList';
import Button from 'components/Button/Button';
import Checkbox from 'components/Checkbox/Checkbox';
import { toast } from 'react-toastify';
import SinglePageLoader from 'components/Loading/SinglePageLoader';
import Loader from 'components/Loader';
import EmptyState from '../emptyState';

const ViewHomePackage = () => {
  const params = useParams();
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setData] = useState();
  const [packageDetails, setPackageDetails] = useState({});
  const navigate = useNavigate();
  const [selectedSubCategories, setSelectedSubCategories] = useState([]);
  const [curatedLoader, setCuratedLoader] = useState(false);
  const [loading, setLoading] = useState(false);
  const [subCategoryLoader, setSubCategoryLoader] = useState(false);
  const queryParams = new URLSearchParams(location.search);
  const name = queryParams?.get('name');

  const fetchWebsiteCategoryDetails = () => {
    setLoading(true);
    dispatch(
      fetchWebsiteCategoryDetailsAction(
        {
          id: params?.id
        },
        (res) => {
          setLoading(false);
          setData(res.data);
        },
        () => {
          setLoading(false);
        }
      )
    );
  };

  useEffect(() => {
    fetchWebsiteCategoryDetails();
  }, []);

  useEffect(() => {
    setSubCategoryLoader(true);
    if (data?.packages?.length) {
      data?.packages?.forEach((item) => {
        dispatch(
          fetchWebsiteSubCategoryDetailsAction(
            { id: item.sub_category_id },
            (res) => {
              setSubCategoryLoader(false);
              setPackageDetails((prevState) => ({
                ...prevState,
                [item.sub_category_id]: res.data
              }));
            },
            () => {
              setSubCategoryLoader(false);
            }
          )
        );
      });
    } else {
      setSubCategoryLoader(false);
    }
  }, [data, dispatch]);

  const handleCheckedCategories = (e) => {
    const { checked, name } = e.target;
    if (checked) {
      setSelectedSubCategories((id) => [...id, name]);
    } else {
      setSelectedSubCategories((ids) => ids.filter((item) => item !== name));
    }
  };

  const handleCuratePackages = () => {
    setCuratedLoader(true);
    dispatch(
      curatePackagesAction(
        {
          is_curated: 'true',
          package_ids: selectedSubCategories
        },
        (res) => {
          setCuratedLoader(false);
          toast.success(res.data.message);
          setSelectedSubCategories([]);
          fetchWebsiteCategoryDetails();
        },
        (err) => {
          setCuratedLoader(false);
          toast.error(err);
        }
      )
    );
  };
  return (
    <DashboardLayout>
      <div>
        <div className={styles.websiteManagement__view__heading}>
          <div>
            <img src={Arrow} onClick={() => navigate(-1)} />
            <h2>{name}</h2>
          </div>
          <Button
            theme="orange"
            disabled={selectedSubCategories?.length === 0}
            loading={curatedLoader}
            onClick={() => handleCuratePackages()}
          >
            Curate Package
          </Button>
        </div>
        {loading ? (
          <SinglePageLoader />
        ) : (
          <div>
            <div>
              <h2 className={styles.websiteManagement__heading}>Description</h2>
              <p style={{ color: '#425466', wordBreak: 'break-word' }}>
                {data?.description || 'No description available'}
              </p>
            </div>

            <div>
              <h2 className={styles.websiteManagement__heading}>Packages</h2>
              <div>
                {data?.packages?.map((item) => {
                  return (
                    <div>
                      <div className={styles.websiteManagement__view__categoryName}>
                        <Checkbox
                          name={item?.sub_category_id}
                          checked={selectedSubCategories?.includes(item?.sub_category_id)}
                          onChange={(e) => handleCheckedCategories(e)}
                          disabled={item?.is_curated === true}
                        />
                        <h5>{item.sub_category_name}</h5>
                      </div>
                      <div>
                        <h5 className={styles.websiteManagement__view__description}>Description</h5>
                        <p className={styles.websiteManagement__view__descriptionCaption}>
                          {packageDetails[item?.sub_category_id]?.description ||
                            'No data available'}
                        </p>
                      </div>
                      <>
                        <h2 className={styles.websiteManagement__view__procedureHeading}>
                          Procedures
                        </h2>
                        <div className={styles.websiteManagement__view__procedures}>
                          {subCategoryLoader ? (
                            <Loader />
                          ) : (
                            packageDetails[item?.sub_category_id]?.procedures?.map((detail) => {
                              return (
                                <div className={styles.websiteManagement__view__procedures__card}>
                                  <CardList title={detail?.procedure_name}>
                                    {detail?.procedure_unique_code}
                                  </CardList>
                                </div>
                              );
                            })
                          )}
                          {packageDetails[item?.sub_category_id]?.procedures?.length === 0 ? (
                            <div className={styles.empty}>
                              <img src={placeholder} alt="" />
                              <p>No data yet</p>
                            </div>
                          ) : null}
                        </div>
                      </>
                    </div>
                  );
                })}
                {data?.packages?.length === 0 ? <EmptyState /> : null}
              </div>
            </div>
            <div>
              <h2 className={styles.websiteManagement__view__procedureHeading}>Procedures</h2>
              <div className={styles.websiteManagement__view__procedures}>
                {subCategoryLoader === true ? (
                  <Loader />
                ) : (
                  data?.procedures?.map((item) => {
                    return (
                      <div className={styles.websiteManagement__view__procedures__card}>
                        <CardList title={item.procedure_name}>
                          {item.procedure_unique_code}
                        </CardList>
                      </div>
                    );
                  })
                )}
              </div>
              {data?.procedures?.length === 0 ? <EmptyState /> : null}
            </div>
          </div>
        )}
      </div>
    </DashboardLayout>
  );
};

export default ViewHomePackage;
